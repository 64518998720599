@import "~antd/dist/antd.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.emulator-container {
  max-height: 1000px;
}

.app-container {
  max-height: 1000px;
}

.template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.template:hover {
  /* background-color: beige; */
}
.template-selected {
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

/* Track */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cecece; 
}