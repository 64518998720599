.androidStudioModal> div{
    border-radius: 8px 8px 0 0;
}
.androidStudioModal>div>div{
   padding: 0;
}
.header{
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    font-weight: 500;
    background: #3f3c3c;
    border-radius: 8px 8px 0 0;
    color: white;
}
.ModalContent{
    height: 530px;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
}
.ModalFooter{
    height: 50px;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ModalmainContent{
    border: 1px solid #a8a8a8;
    border-radius: 10px 10px 0 0;
}
.ModelContentTemp{
    width: 25%;
    border-right: 1px solid #d1d1d1;
}
.listoftemplates{
    display: flex;
    flex-direction: column;
}
.listitem{
    /* margin-left: 20px; */
    height: 30px;
    display: flex;
    align-items: center;
    cursor: default;
}
.listitem_active{
    background: #d5d5d5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.listoftemplatesHeading{
    color:#9a9c9f;
    padding: 20px;
    cursor: default;
}
.activitymaindiv{
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 27px
}
.activitymain{
    border: 1px solid #d5d5d5;
    padding-top: 20px;
}
.activitymainfocus{
    border: 1px solid #0075ba;
    padding-top: 20px;
}
.activityfooter{
    display: flex;
    justify-content: center;
    border-top: 1px solid #d5d5d5;
    margin-top: 15px;
    padding: 3px;
    background: #d5d5d5;
}
.activityfooterfocus{
    display: flex;
    justify-content: center;
    border-top: 1px solid #0075ba;
    margin-top: 15px;
    padding: 3px;
    background: #0075ba;
    color: white;
}
.activitymainfocusout{
    padding-top: 20px;
}
.activityfooterfocusout{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding: 3px;
}
.custmbtn{
    margin: 0 5px;
    height: 27px;
    display: flex;
    align-items: center;
    border-radius: 6px;
}
.activity2ndpgContent{
    padding-left: 70px;
    padding-top: 50px;
    padding-right: 70px;
    width: 100%;
    background: #f2f2f2;
}
.activity2ndpgDiv{
    padding: 8px 0px;
    display: flex;
}
.activity2ndpgDiv:first-child{
    font-weight: 500;
    font-size: 15px;
}
.activity2ndpgDivinner{
    width: 150px;
}
.activity2ndpginput{
    width: 100%;
}
.activity2ndpginput>input{
    border: 1px solid #d5d5d5;
    width: 100%;
    padding: 0 5px;
}
.activity2ndpginput>input:focus{
    outline: none;
  	border: 1px solid #0075ba;
    box-shadow:  0px 0px 0px 1px #389fd6;
    padding: 0 5px;
}
.menu{
    background: #f2f2f2;
    height: 30px;
    padding: 0 9px;
    display: flex;
    align-items: center;
}